.participant_list {
    position: relative;
    display: flex;
    margin-bottom: 1em;
}

.participant_list ul{    
    margin-top: 1em;
}

.participant_list li {
    padding: 5px;
    box-shadow: 1px 1px 5px #ccc;
    width: auto;
    float: left;
    margin-right: 5px;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.participant_list li:first-child {
    box-shadow: none;
    font-weight: bold;
    color: #000;
}

.participant_list li a {    
    flex-direction: column;
}

.participant_list li a div:last-child {    
    flex-direction: row;
}