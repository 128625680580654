.tag_post_page .publish {
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-self: center;
}

.tag_post_page .publishing {
  padding: 0.5em;
  color: #E5E5E5;
}

.tag_post_page .message {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.tag_post_page .form {
  margin-bottom: 2em;
}
